// extracted by mini-css-extract-plugin
export var CardWrapper = "RobertNava-module--CardWrapper--b1H+s";
export var CarrouselWrapper = "RobertNava-module--CarrouselWrapper--6ULz+";
export var DescriptionWrapper = "RobertNava-module--DescriptionWrapper--xYFvT";
export var ExpoWrapper = "RobertNava-module--ExpoWrapper--JqQsA";
export var NameWrapper = "RobertNava-module--NameWrapper--HETI9";
export var PdpWrapper = "RobertNava-module--PdpWrapper--ehC5t";
export var PhotosWrapper = "RobertNava-module--PhotosWrapper--8vQeU";
export var ProfilWrapper = "RobertNava-module--ProfilWrapper--4HxEQ";
export var VideoWrapper = "RobertNava-module--VideoWrapper--jZLCv";
export var VideosWrapper = "RobertNava-module--VideosWrapper--JoM1m";
export var WorkWrapper = "RobertNava-module--WorkWrapper--aZgkj";
export var WorksWrapper = "RobertNava-module--WorksWrapper--gClTw";
export var Wrapper = "RobertNava-module--Wrapper--WvALO";